<template>
    <create-view v-on:create-view="createViewInTeam" v-on:cancel="cancelCreate" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import createView from '@/views/views/create';

export default {
    name: 'team-view-create',
    data: () => ({}),
    props: {},
    components: {
        createView,
    },
    mounted() {},
    computed: {
        ...mapGetters(['team']),
    },
    methods: {
        ...mapActions(['addTeamView']),
        cancelCreate() {
            this.$router.go(-1);
        },
        createViewInTeam(view) {
            const payload = {
                team: this.team.id,
                view: view,
            };

            this.addTeamView(payload);
            this.$router.go(-1);
        },
    },
};
</script>
